<template>
    <div class="free-trial-area">
        <div class="container">
            <div class="free-trial-inner">
                <h2 class="nunito-font" data-aos="fade-up" data-aos-duration="1200">Get connected with Vumy platform</h2>
                <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed tortor, cursus dui vulputate sit elit. Elementum et a, bibendum mi nisl. Sed facilisis sed turpis gravida faucibus tortor sed at.</p>
                <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <router-link to="/contact." class="btn-style-one green-color2">
                        Free Trial Now 
                        <i class="ph-caret-right"></i>
                    </router-link>
                    <router-link to="/contact" class="btn-style-one white-color">
                        Watch Demo 
                        <i class="ph-caret-right"></i>
                    </router-link>
                </div>
                <div class="shape12"><img src="../../assets/images/shape/shape12.png" alt="shape"></div>
                <div class="shape13"><img src="../../assets/images/shape/shape13.png" alt="shape"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FreeTrial'
}
</script>