<template>
    <div class="blog-area pb-75 pt-100">
        <div class="container">
            <div class="section-title">
                <h2 class="nunito-font">Our latest articles & resources</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-blog-post-item green-color">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog5.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <router-link to="/blog-tag">Design</router-link>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    24 Nov, 2021
                                </li>
                            </ul>
                            <h3 class="nunito-font">
                                <router-link to="/blog-details">How sellers win when housing inventory is low</router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis.</p>
                            <router-link to="/blog-details" class="link-btn">
                                Read More 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-blog-post-item green-color">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog4.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <router-link to="/blog-tag">Technology</router-link>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    25 Nov, 2021
                                </li>
                            </ul>
                            <h3 class="nunito-font">
                                <router-link to="/blog-details">Why a wave of foreclosures is not on the way</router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis.</p>
                            <router-link to="/blog-details" class="link-btn">
                                Read More 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-blog-post-item green-color">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog6.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <router-link to="/blog-tag">Startup</router-link>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    23 Nov, 2021
                                </li>
                            </ul>
                            <h3 class="nunito-font">
                                <router-link to="/blog-details">Why now is a great time to sell your house</router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis.</p>
                            <router-link to="/blog-details" class="link-btn">
                                Read More 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>