<template>
    <div class="resources-area pt-100 pb-75 bg-f9f9f9">
        <div class="container">
            <div class="section-title text-start" data-aos="fade-up" data-aos-duration="1200">
                <h2 class="nunito-font">Big data resources</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet.</p>
            </div>
            <div class="resources-slides">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :wrapAround="true"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-resources-box">
                            <router-link to="/portfolio-details" class="image d-block">
                                <img :src="slide.image" alt="resources">
                            </router-link>
                            <div class="content">
                                <h3 class="nunito-font">
                                    <router-link to="/portfolio-details">{{slide.title}}</router-link>
                                </h3>
                                <p>{{slide.desc}}</p>
                                <router-link to="/portfolio-details" class="link-btn">
                                    Learn More 
                                    <i class="ph-caret-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='flaticon-right-arrow'></i>
                            </template>
                            <template #prev>
                                <i class='flaticon-left-arrow'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'DataResources',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/resources/resources1.jpg'),
                title: 'Free Data Sources For Your Next Project',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.',
            },
            {
                id: 2,
                image: require('../../assets/images/resources/resources2.jpg'),
                title: 'Gaining New Insights from Data Lakes',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.',
            },
            {
                id: 3,
                image: require('../../assets/images/resources/resources3.jpg'),
                title: 'Top 10 Data Resources Online in 2022',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.',
            },
            {
                id: 4,
                image: require('../../assets/images/resources/resources4.jpg'),
                title: 'Free Data Sources For Your Next Project',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.',
            },
            {
                id: 5,
                image: require('../../assets/images/resources/resources5.jpg'),
                title: 'Gaining New Insights from Data Lakes',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.',
            },
            {
                id: 6,
                image: require('../../assets/images/resources/resources6.jpg'),
                title: 'Top 10 Data Resources Online in 2022',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur mauris amet, placerat fames orci elementum adipiscing consectetur sagittis.',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
        },
    }),
})
</script>